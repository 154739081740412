'use client';

import { FC } from 'react';
import Main from 'components/page/error/500';

const AdvertisementListingErrorPage: FC = () => {
    return <Main
        errorPageTitle='Prepáčte, niečo sa pokazilo.'
        errorPageText='Skúste opätovne načítať stránku. Ak problém pretrváva, kontaktujte používateľskú podporu.'
        reloadButtonText='Znova načítať stránku'
        contactButtonText='Kontaktovať support'

    />;
};

export default AdvertisementListingErrorPage;